(() => {
  // REGISTER SERVICE WORKER
  (() => {
    const registerServiceWorker = async () => {
      if (`serviceWorker` in navigator) {
        try {
          const registration = await navigator.serviceWorker.register(
            `/service-worker.js`,
            {
              scope: `/`,
            }
          );

          if (registration.installing) {
            console.log(`Service worker installing`);
          } else if (registration.waiting) {
            console.log(`Service worker installed`);
          } else if (registration.active) {
            console.log(`Service worker active`);
          }
        } catch (error) {
          console.error(`Registration failed with ${error}`);
        }
      }
    };

    registerServiceWorker();
  })();

  // HEADER MENU
  (() => {
    const $headerMenuOpen = document.getElementById(`headerMenuOpen`);
    const $headerMenuClose = document.getElementById(`headerMenuClose`);

    $headerMenuOpen.addEventListener(`click`, () => {
      $headerMenuOpen.setAttribute(`aria-expanded`, true);
      $headerMenuClose.setAttribute(`aria-expanded`, true);

      document.body.classList.add(`body__menu--open`);
      $headerMenuClose.focus();
    });

    $headerMenuClose.addEventListener(`click`, () => {
      $headerMenuOpen.setAttribute(`aria-expanded`, false);
      $headerMenuClose.setAttribute(`aria-expanded`, false);

      document.body.classList.remove(`body__menu--open`);
      $headerMenuOpen.focus();
    });
  })();

  // EMAIL ADDRESSES
  (() => {
    [
      ...document.querySelectorAll(
        `a[href^="mailto:"][href*="(at)"][href*="(dot)"]`
      ),
    ].forEach((link) => {
      link.href = link.href.replace(`(at)`, `@`).replace(/\(dot\)/g, `.`);
    });
  })();
})();
